@font-face {
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/static/Quicksand-Regular.ttf") format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/static/Quicksand-Light.ttf") format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/static/Quicksand-Medium.ttf") format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/static/Quicksand-SemiBold.ttf") format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/static/Quicksand-Bold.ttf") format('opentype');
  font-weight: 600;
}

body {
  font-family: 'Quicksand';
}

#root {
  background-color: #857E7B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
