.carousel {
    height: 700px;
}

.carousel-inner {
    height: 700px;
}
video {
    width: auto;
    height: 700px;
}

@media screen and (max-width: 767px) {   
    #mapa {
        height: 300px;
        width: auto;
    }
    .carousel {
        height: 300px;
    }
    .carousel-inner {
        height: 300px;
    }
    video {
        width: auto;
        height: 300px;
    }
    }

@media screen and (min-width: 768px) {      
    #mapa {
        margin-left: 8px;
        height: 700px;
        width: auto;
    }
} 
@media (min-width: 992px) {      
    #mapa {
        margin-left: 10px;
        height: 700px;
        width: auto;
    }
}
@media (min-width: 1200px) {    
    #mapa {
        margin-left: 170px;
        height: 700px;
        width: auto;
    }
}

#title-map {
    color: #000;
}

#text-map {
    color: #000;
}

#title-video {
    color: #fff;
}