#trayectoria {
    text-align: center;
    color: white;
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 30px;
}

#trayectoriaI {
    text-align: center;
    color: white;
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 30px;
}

#trayectoriaA {
    text-align: center;
    color: white;
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 30px;
}
#Component {
    color: white;
    font-family: 'Quicksand';
    font-weight: 400;
    text-align: center;
}

#Component img {
    padding-bottom: 8px;
}

#Contact {
    height: 200px;
    margin-top: 50px;
}

#Contact p {
    height: 100px;
    color: white;
    font-family: 'Quicksand';
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {   
    #Contact p {font-size: 18px;}
    #trayectoria {
        padding-top: 5px;}
    #trayectoriaI {
            padding-top: 5px}
    #trayectoriaA {
                padding-top: 5px;}
    #Component {
                font-size: 25px;
                }
    }

@media screen and (min-width: 768px) {      
    #Contact p {font-size: 20px;}
    #trayectoria {
        padding-top: 10px;}
    #trayectoriaI {
            padding-top: 10px}
    #trayectoriaA {
                padding-top: 10px;}
    #Component {
                font-size: 30px;
                }
} 
@media (min-width: 992px) {      
    #Contact p {font-size: 22px;}
    #trayectoria {
        padding-top: 25px;}
    #trayectoriaI {
            padding-top: 25px}
    #trayectoriaA {
                padding-top: 25px;}
    #Component {
                font-size: 50px;
                }
}
@media (min-width: 1200px) {    
    #Contact p {font-size: 25px;}
    #trayectoria {
        padding-top: 30px;}
    #trayectoriaI {
            padding-top: 30px}
    #trayectoriaA {
                padding-top: 30px;}
    #Component {
        font-size: 50px;
    }
}