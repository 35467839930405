#contain-footer {
    background: #363738 !important;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
    margin: 0%;
}

#contain-footer .contacts {
    font-family: 'Quicksand';
    font-weight: 200;
    color:white;
}


@media screen and (max-width: 767px) {   
    #contain-footer .contacts { font-size: 10px;}
    #contain-footer {height: 400px;}
    }

@media screen and (min-width: 768px) {      
    #contain-footer .contacts { font-size: 10px;}
    #contain-footer {height: 370px;}
} 
@media (min-width: 992px) {      
    #contain-footer .contacts { font-size: 15px;}
    #contain-footer {height: 200px;} 
}
@media (min-width: 1200px) {    
    #contain-footer .contacts { font-size: 15px;}
    #contain-footer {height: 200px;}
}

.contacts {
    text-decoration: none;
    text-align: center;
    color:white;
}

.contacts:active {
    color:white;
}

.contacts:link {
    color:white;
}

.contacts:hover {
    color:white;
}

.contacts:visited {
    color:white;
}

.enlaces {
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color:white;
}

.enlaces {
    text-decoration: none;
}

.enlaces:active {
    color:white;
}

.enlaces:link {
    color:white;
}

.enlaces:hover {
    color:white;
}

.enlaces:visited {
    color:white;
}