#hero-img {
    height: 300px;
    background-color: #373839;
    background: linear-gradient(to bottom, rgb(220, 221, 222), #373839), url("../img/665C2A21-59E6-437F-8B8D-405215BBB996_1_105_c.jpeg");
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0%;
}

.typewrite {
    font-size: 65px;
    color: white;
    font-stretch: extra-expanded;
    font-family: 'Quicksand';
    font-weight: 400;
}