#Icon {
    height: 60px;
    width: 60px;
}

#hero {
    margin:0%;
}

#gallery {
    margin: 10px;
}


.carousel {
    height: 350px;
}

.carousel-inner {
    height: 300px;
}
video {
    width: auto;
    height: 300px;
}